import Layout from "../components/Layout"

const BlogPostTemplate = ({
  pageContext: {
    title,
    content,
    image: {
      fixed: { src: image },
    },
    metaDescription: {
      childMarkdownRemark: { html: metaDescription },
    },
  },
}) => {
  return (
    <Layout
      title={title}
      backgroundImage={image}
      backgroundFit
      description={content}
      metaDescription={metaDescription}
    />
  )
}

export default BlogPostTemplate
